import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"

// import mcCarthyLogo from ''
import mcCarthyLogo from '../../images/sponsors/mccarthy-family-foundation-logo.jpg'
import bostonGlobeLogo from '../../images/sponsors/boston-globe.png'
import bostonGlobeFoundationLogo from '../../images/sponsors/boston-globe-foundation.png'
import westminsterCollegeLogo from '../../images/sponsors/westminster.png'
import westminsterStudents from '../../images/sponsors/westminster-college-students.jpg'
import westminsterInUtah from '../../images/sponsors/Westminster-in-Utah.jpg'


import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import LeftMenu from './leftMenu'
const ParticipatingNewsOutlets = () => (
  <Layout>
    <SEO title="National Student Essay Competition | Participating News Outlets" />

    <div className="subpageContainer">

      <LeftMenu active="participating-news-outlets" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>Participating News Outlets</h1>
        
        <h2>Newspapers Supporting the National Student Essay Competition</h2>


        <p>We deeply appreciate the news outlets that have volunteered their precious time and skills to make the untapped, yet powerful voices of students in support of press freedom heard loud and clear.</p>

        <ul>
          <li>Black Voice News</li>
          <li>The Boston Globe</li>
          <li>The Citizen Chronicle</li>
          <li>The Daily World</li>
          <li>The Examiner </li>
          <li>The White Plains Examiner</li>
          <li>The Northern Westchester Examiner </li>
          <li>The Putnam Examiner </li>
          <li>TheExaminerNews.com</li>
          <li>The Havana Herald </li>
          <li>The Chattahoochee News-Herald &amp; Sneads Sentinel </li>
          <li>Ponte Vedra NewsLine</li>
          <li>Mandarin NewsLine</li>
          <li>MLive Media Group</li>
          <ul>
            <li>The Ann Arbor News</li>
            <li>The Grand Rapids Press </li>
            <li>The Flint Journal</li>
            <li>The Kalamazoo Gazette </li>
            <li>The Saginaw News</li>
            <li>The Jackson Citizen Patriot </li>
            <li>The Muskegon Chronicle </li>
            <li>The Bay City Times</li>
            <li>MLive.com</li>
          </ul>
          <li>The Creek Line</li>
          <li>The Plymouth Review </li>
          <li>The Tennessean</li>
          <li>TucsonSentinel.com</li>
          <li>The Yellow Springs News</li>
          <li>Sacramento Valley News</li>
          <li>YourArlington.com</li>
        </ul>
        
        <p><strong>Note to Students:</strong><br />
        If your local news outlet is not listed, email your essay with the required information to <a href="mailto:studentessays@mklpr.com">studentessays@mklpr.com</a>.
</p>

        


      </div>
    </div>

    

  </Layout>
)

export default ParticipatingNewsOutlets
